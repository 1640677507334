<template>
    <div class="text-center my-4 winners">
      <div class="text-white" v-if="!mobileView">
        <h4 class="text Sansrace-Regular">
          CONGRATULATIONS TO THE WINNERS OF THE NATIONAL
        </h4>
        <h4 class="text Sansrace-Regular">
          HEALTHCARE INNOVATION AND PRODUCTIVITY MEDALS 2023
        </h4>
      </div>
      <div class="text-white" v-if="mobileView">
        <h4 class="text Sansrace-Regular top-title">
          CONGRATULATIONS TO THE WINNERS OF
        </h4>
        <h4 class="text Sansrace-Regular top-title">
          THE NATIONAL HEALTHCARE INNOVATION
        </h4>
        <h4 class="text Sansrace-Regular top-title">
          AND PRODUCTIVITY MEDALS 2023
        </h4>
      </div>
      <div class="my-4">
        <a href="https://www.chi.sg/files/chi%20press%20release%2028%20july%202023.pdf" target="_blank">
          <img
            class="press-img"
            src="@/assets/img/nhip/Winners ( placeholders )/desktop/Press release/press release.png"
            alt="Press release"
          />
        </a>
        
      </div>
      <div>
        <div>
          <h2 class="title-winner font-weight-bold mb-3 text Sansrace-Regular">
            WINNERS
          </h2>
          <h4 class="text-white my-3 text-champ Nexa-bold">
            EXCELLENCE CHAMPION MEDAL
          </h4>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-4 col-6">
            <a href="/Winners/pdfs/1.pdf" target="_blank">
            <img
              class="img-fluid"
              src="@/assets/img/nhip/winners/1. excellence awards/desktop/1.png"
              alt=""
            />
            </a>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-center">
          <h3 class="text-white my-3 text">BEST PRACTICE MEDAL</h3>
          <img
            class="care-img mb-3"
            src="@/assets/img/nhip/Winners ( placeholders )/desktop/Care design/care design.png"
            alt=""
          />
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-sm-4 col-6">
            <a href="/Winners/pdfs/2.pdf" target="_blank">
              <img
                class="img-fluid"
                src="@/assets/img/nhip/winners/2. care redesign/desktop/1.png"
                alt=""
              />
            </a>
          </div>
          <div class="col-sm-4 col-6">
            <a href="/Winners/pdfs/3.pdf" target="_blank">
              <img
                class="img-fluid"
                src="@/assets/img/nhip/winners/2. care redesign/desktop/2.png"
                alt=""
              />
            </a>
          </div>
          <div class="col-sm-4 col-6">
            <a href="/Winners/pdfs/4.pdf" target="_blank">   
              <img
                class="img-fluid"
                src="@/assets/img/nhip/winners/2. care redesign/desktop/3.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
  
      <div class="mt-5">
        <div class="row">
          <div class="col-12 text-center">
            <img
              class="auto-img mb-3"
              src="@/assets/img/nhip/automated it and rbotics/desktop/automated IT.png"
              alt=""
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-4 col-6">
              <a href="/Winners/pdfs/5.pdf" target="_blank">
            <img
              class="img-fluid"
              src="@/assets/img/nhip/winners/3. automation/desktop/1.png"
              alt=""
            />
          </a>
          </div>
          <div class="col-sm-4 col-6">
              <a href="/Winners/pdfs/6.pdf" target="_blank">
            <img
              class="img-fluid"
              src="@/assets/img/nhip/winners/3. automation/desktop/2.png"
              alt=""
            />
          </a>
          </div>
        </div>
      </div>
  
      <div class="mt-5">
        <div class="row">
          <div class="col-12 text-center">
            <img
              class="work-img mb-3"
              src="@/assets/img/nhip/workforce transformation/desktop/title desktop.png"
              alt=""
            />
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-sm-4 col-6">
            <a href="/Winners/pdfs/7.pdf" target="_blank">
              <img
                class="img-fluid"
                src="@/assets/img/nhip/winners/4. workforce/desktop/1.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Winners",
  
    data() {
      return {
        mobileView: false,
      };
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
    methods: {
      handleResize() {
        this.mobileView = window.innerWidth < 768;
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .winners {
    .Sansrace-Regular {
      font-family: "Sansrace-Regular";
    }
    .Nexa-bold {
      font-family: "Nexa-Bold";
    }
    .title-winner {
      color: #c3efa9;
      text-shadow: 0px 0px 10px black;
    }
    .press-img {
      width: 50%;
    }
    .auto-img {
      width: 70%;
    }
    .work-img {
      width: 60%;
    }
    .care-img {
      width: 33%;
    }
    .btn {
      border-radius: 13px;
      font-family: Sansrace, regular;
      width: 80%;
    }
  }
  
  @media screen and (max-width: 576px) {
    .text {
      font-size: 20px;
    }
    .text-champ {
      font-size: 16px;
    }
    .top-title {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 340px) {
    .top-title {
      font-size: 14px;
    }
  }
  </style>