<template>
  <div class="nhip">
    <HeroVideo />
    <div class="nhip-content">
      <div class="title-nhip text-center py-4">
        <h1
          class="text-uppercase Sansrace-Regular mb-0"
          v-if="type == 'desktop'"
        >
          National Healthcare Innovation And Productivity Medals
        </h1>
        <h3 class="text-uppercase Sansrace-Regular mb-0" v-else>
          National Healthcare Innovation And Productivity Medals
        </h3>
      </div>
      <div class="detail py-4">
        <div class="container-xl">
          <div class="row" v-if="type == 'desktop'">
            <div class="col-sm-8">
              <p>
                Singapore’s healthcare is expanding our focus: from healthcare
                to health; from patients to people; from clinical centres to
                communities of care.
              </p>
              <p>
                Our Healthcare and Community Care institutions are determined to
                breach traditional boundaries and work with one another to
                catalyse health and social change, ultimately creating more
                sustainable healthcare and healthy communities. Through care
                redesign, smart systems and technologies, and workforce
                transformation, we can better deliver value through innovation
                and productivity.
              </p>
              <p>
                Supported by the Ministry of Health (MOH), the annual National
                Healthcare Innovation and Productivity (HIP) Medals provide an
                opportunity for Healthcare and Community Care institutions to
                acknowledge, showcase and celebrate excellence in healthcare
                innovation and productivity.
              </p>
            </div>

            <div class="col-sm-4">
              <img src="@/assets/img/nhip/medal.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-7">
              <p>
                Singapore’s healthcare is expanding our focus: from healthcare
                to health; from patients to people; from clinical centres to
                communities of care.
              </p>
            </div>

            <div class="col-5">
              <img
                src="@/assets/img/nhip/medal-mobile.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-12">
              <p>
                Our Healthcare and Community Care institutions are determined to
                breach traditional boundaries and work with one another to
                catalyse health and social change, ultimately creating more
                sustainable healthcare and healthy communities. Through care
                redesign, smart systems and technologies, and workforce
                transformation, we can better deliver value through innovation
                and productivity.
              </p>
              <p>
                Supported by the Ministry of Health (MOH), the annual National
                Healthcare Innovation and Productivity (HIP) Medals provide an
                opportunity for Healthcare and Community Care institutions to
                acknowledge, showcase and celebrate excellence in healthcare
                innovation and productivity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="past-awards py-4">
        <div class="container-xl">
          <div class="row">
            <div class="col-sm-3 mb-sm-0 mb-2">
              <img
                src="@/assets/img/nhip/past-award.png"
                class="img-fluid"
                alt=""
                v-if="type == 'desktop'"
              />
              <img
                src="@/assets/img/nhip/past-award-mobile.png"
                class="img-fluid"
                alt=""
                v-else
              />
            </div>
            <div class="col-sm-9">
              <div class="row">
                <div
                  class="col-sm-3 col-6 mb-sm-0 mb-2 text-center pointer"
                  @click="
                    openModal('https://www.youtube.com/embed/0y7M_8a_7CI')
                  "
                >
                  <img
                    src="@/assets/img/nhip/thumbnail/1.png"
                    class="img-fluid mb-2"
                    alt=""
                  />
                  <p>2022</p>
                </div>
                <div
                  class="col-sm-3 col-6 mb-sm-0 mb-2 text-center pointer"
                  @click="
                    openModal('https://www.youtube.com/embed/UFKoedLOhV4')
                  "
                >
                  <img
                    src="@/assets/img/nhip/thumbnail/2.png"
                    class="img-fluid mb-2"
                    alt=""
                  />
                  <p>2021</p>
                </div>
                <div
                  class="col-sm-3 col-6 mb-sm-0 mb-2 text-center pointer"
                  @click="
                    openModal('https://www.youtube.com/embed/DZ1uk_0DeKg')
                  "
                >
                  <img
                    src="@/assets/img/nhip/thumbnail/3.png"
                    class="img-fluid mb-2"
                    alt=""
                  />
                  <p>2020</p>
                </div>
                <div
                  class="col-sm-3 col-6 mb-sm-0 mb-2 text-center pointer"
                  @click="
                    openModal('https://www.youtube.com/embed/AAYelEYxNA0')
                  "
                >
                  <img
                    src="@/assets/img/nhip/thumbnail/4.png"
                    class="img-fluid mb-2"
                    alt=""
                  />
                  <p>2019</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative">
        <div
          class="position-absolute left-box-background"
          v-if="isLargeScreen"
        ></div>
        <div
          class="position-absolute content-back-img"
          v-if="isLargeScreen"
        ></div>
        <div class="nhip-winners container-xl">
          <div class="row">
            <div class="col-4 py-5 btn-box text-center" v-if="isLargeScreen">
              <div>
                <button
                  :class="{ disabled: activeBtn !== 'winner' }"
                  @click="setActiveButton('winner')"
                  class="btn btn-primary btn-block btn-lg py-3 winner-btn border-0 font-weight-bold text-white"
                >
                  WINNERS
                </button>
              </div>
              <div class="mt-4 font-weight-bold">
                <p class="text-white">Shortlisted:</p>
                <button
                  :class="{ disabled: activeBtn !== 'care' }"
                  @click="setActiveButton('care')"
                  class="btn btn-primary btn-block btn-lg py-3 care-btn border-0 font-weight-bold text-white"
                >
                  CARE REDESIGN
                </button>
              </div>
              <div class="my-4">
                <button
                  :class="{ disabled: activeBtn !== 'automated' }"
                  @click="setActiveButton('automated')"
                  class="btn btn-primary btn-block btn-lg py-3 auto-btn border-0 font-weight-bold text-white"
                >
                  AUTOMATION, IT AND ROBOTICS INNOVATION
                </button>
              </div>
              <div>
                <button
                  :class="{ disabled: activeBtn !== 'workforce' }"
                  @click="setActiveButton('workforce')"
                  class="btn btn-primary btn-block btn-lg py-3 work-btn border-0 font-weight-bold text-white"
                >
                  WORKFORCE TRANSFORMATION
                </button>
              </div>
            </div>
            <div
              class="col-12 col-md-8 py-4"
              :class="{ 'shortlisted-content': !isLargeScreen }"
            >
              <div id="mobile" class="my-5" v-if="type == 'mobile'">
                <button
                  @click="setActiveButton('winner', true)"
                  id="sec-winner"
                  class="btn btn-primary btn-block btn-lg py-3 winner-btn border-0 font-weight-bold text-white"
                >
                  WINNERS
                </button>
                <Winners v-if="activeBtn == 'winner'" />
                <p class="text-white font-weight-bold mb-1">Shortlisted:</p>
                <div>
                  <a
                    href="javascript:void(0)"
                    @click="setActiveButton('care', true)"
                    id="sec-care"
                  >
                    <img
                      class="img-fluid"
                      src="@/assets/img/nhip/Care redesign/mobile/1.png"
                      alt=""
                    />
                  </a>
                </div>

                <Shortlisted
                  v-if="activeBtn == 'care'"
                  :awardsData="activeData"
                  :name="activeBtn"
                  :desktop="false"
                />
                <div class="my-3">
                  <a
                    href="javascript:void(0)"
                    id="sec-automated"
                    @click="setActiveButton('automated', true)"
                  >
                    <img
                      class="img-fluid"
                      src="@/assets/img/nhip/automated it and rbotics/mobile/2.png"
                      alt=""
                    />
                  </a>
                </div>
                <Shortlisted
                  v-if="activeBtn == 'automated'"
                  :awardsData="activeData"
                  :name="activeBtn"
                  :desktop="false"
                />
                <div>
                  <a
                    href="javascript:void(0)"
                    id="sec-workforce"
                    @click="setActiveButton('workforce', true)"
                  >
                    <img
                      class="img-fluid"
                      src="@/assets/img/nhip/workforce transformation/mobile/3.png"
                      alt=""
                    />
                  </a>
                </div>
                <Shortlisted
                  v-if="activeBtn == 'workforce'"
                  :awardsData="activeData"
                  :name="activeBtn"
                  :desktop="false"
                />
              </div>
              <div v-else>
                <Winners v-if="activeBtn == 'winner'" />
                <Shortlisted
                  :awardsData="activeData"
                  :name="activeBtn"
                  :desktop="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact py-3">
      <div class="container-xl">
        <div class="text-center py-4">
          <div class="row">
            <div class="col-sm-3 col-9 mx-auto">
              <a
                href="mailto:nhip@chi.sg?subject=Enquiry on National HIP Medals 2023"
                class="contact-btn text-decoration-none"
              >
                CONTACT US
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-envelope-fill ml-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      aria-labelledby="videoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
            <div
              class="embed-responsive embed-responsive-16by9"
              v-if="!hide_video"
            >
              <iframe
                class="embed-responsive-item"
                src=""
                id="video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";
import Shortlisted from "@/components/nhip/shortlisted.vue";
import Winners from "@/components/nhip/winners.vue";

export default {
  name: "nhip",
  components: {
    HeroVideo,
    Footer,
    Shortlisted,
    Winners
  },
  data() {
    return {
      type: "desktop",
      hide_video: true,
      videoSrc: "",
      activeBtn: "winner",
      isLargeScreen: true,
      activeData: [],
      cards: [
        {
          id: 1,
          name: "care",
          img: "/Care redesign/1.png",
          pdfLink: "/Care redesign/pdf/C1.pdf",
        },
        {
          id: 2,
          name: "care",
          img: "/Care redesign/2.png",
          pdfLink: "/Care redesign/pdf/C2.pdf",
        },
        {
          id: 3,
          name: "care",
          img: "/Care redesign/3.png",
          pdfLink: "/Care redesign/pdf/C3.pdf",
        },
        {
          id: 4,
          name: "care",
          img: "/Care redesign/4.png",
          pdfLink: "/Care redesign/pdf/C4.pdf",
        },
        {
          id: 5,
          name: "care",
          img: "/Care redesign/5.png",
          pdfLink: "/Care redesign/pdf/C5.pdf",
        },
        {
          id: 6,
          name: "care",
          img: "/Care redesign/6.png",
          pdfLink: "/Care redesign/pdf/C6.pdf",
        },
        {
          id: 7,
          name: "care",
          img: "/Care redesign/7.png",
          pdfLink: "/Care redesign/pdf/C7.pdf",
        },
        {
          id: 7,
          name: "care",
          img: "/Care redesign/8.png",
          pdfLink: "/Care redesign/pdf/C8.pdf",
        },
        {
          id: 9,
          name: "care",
          img: "/Care redesign/9.png",
          pdfLink: "/Care redesign/pdf/C9.pdf",
        },
        {
          id: 10,
          name: "care",
          img: "/Care redesign/10.png",
          pdfLink: "/Care redesign/pdf/C10.pdf",
        },
        {
          id: 11,
          name: "care",
          img: "/Care redesign/11.png",
          pdfLink: "/Care redesign/pdf/C11.pdf",
        },
        {
          id: 12,
          name: "care",
          img: "/Care redesign/12.png",
          pdfLink: "/Care redesign/pdf/C12.pdf",
        },
        {
          id: 13,
          name: "care",
          img: "/Care redesign/13.png",
          pdfLink: "/Care redesign/pdf/C13.pdf",
        },
        {
          id: 14,
          name: "care",
          img: "/Care redesign/14.png",
          pdfLink: "/Care redesign/pdf/C14.pdf",
        },
        {
          id: 15,
          name: "care",
          img: "/Care redesign/15.png",
          pdfLink: "/Care redesign/pdf/C15.pdf",
        },
        {
          id: 16,
          name: "care",
          img: "/Care redesign/16.png",
          pdfLink: "/Care redesign/pdf/C16.pdf",
        },
        {
          id: 17,
          name: "care",
          img: "/Care redesign/17.png",
          pdfLink: "/Care redesign/pdf/C17.pdf",
        },
        {
          id: 18,
          name: "care",
          img: "/Care redesign/18.png",
          pdfLink: "/Care redesign/pdf/C18.pdf",
        },
        {
          id: 19,
          name: "care",
          img: "/Care redesign/19.png",
          pdfLink: "/Care redesign/pdf/C19.pdf",
        },
        {
          id: 20,
          name: "care",
          img: "/Care redesign/20.png",
          pdfLink: "/Care redesign/pdf/C20.pdf",
        },
        {
          id: 21,
          name: "care",
          img: "/Care redesign/21.png",
          pdfLink: "/Care redesign/pdf/C21.pdf",
        },
        {
          id: 22,
          name: "care",
          img: "/Care redesign/22.png",
          pdfLink: "/Care redesign/pdf/C22.pdf",
        },
        {
          id: 23,
          name: "care",
          img: "/Care redesign/24.png",
          pdfLink: "/Care redesign/pdf/C23.pdf",
        },
        {
          id: 23,
          name: "care",
          img: "/Care redesign/23.png",
          pdfLink: "/Care redesign/pdf/C24.pdf",
        },
        {
          id: 24,
          name: "care",
          img: "/Care redesign/25.png",
          pdfLink: "/Care redesign/pdf/C25.pdf",
        },
        {
          id: 26,
          name: "care",
          img: "/Care redesign/26.png",
          pdfLink: "/Care redesign/pdf/C26.pdf",
        },
        {
          id: 27,
          name: "care",
          img: "/Care redesign/C27.png",
          pdfLink: "/Care redesign/pdf/C27.pdf",
        },
        {
          id: 28,
          name: "care",
          img: "/Care redesign/C28.png",
          pdfLink: "/Care redesign/pdf/C28.pdf",
        },
        {
          id: 29,
          name: "care",
          img: "/Care redesign/C29.png",
          pdfLink: "/Care redesign/pdf/C29.pdf",
        },
        {
          id: 30,
          name: "care",
          img: "/Care redesign/30.png",
          pdfLink: "/Care redesign/pdf/C30.pdf",
        },
        {
          id: 31,
          name: "care",
          img: "/Care redesign/31.png",
          pdfLink: "/Care redesign/pdf/C31.pdf",
        },
        {
          id: 32,
          name: "care",
          img: "/Care redesign/32.png",
          pdfLink: "/Care redesign/pdf/C32.pdf",
        },
        {
          id: 33,
          name: "care",
          img: "/Care redesign/33.png",
          pdfLink: "/Care redesign/pdf/C33.pdf",
        },
        {
          id: 34,
          name: "care",
          img: "/Care redesign/34.png",
          pdfLink: "/Care redesign/pdf/C34.pdf",
        },
        {
          id: 9,
          name: "care",
          img: "/automated it and rbotics/9.png",
          pdfLink: "/automated it and rbotics/pdf/A9.pdf",
        },
        {
          id: 1,
          name: "automated",
          img: "/automated it and rbotics/1.png",
          pdfLink: "/automated it and rbotics/pdf/A1.pdf",
        },
        {
          id: 2,
          name: "automated",
          img: "/automated it and rbotics/2.png",
          pdfLink: "/automated it and rbotics/pdf/A2.pdf",
        },

        {
          id: 3,
          name: "automated",
          img: "/automated it and rbotics/3.png",
          pdfLink: "/automated it and rbotics/pdf/A3.pdf",
        },

        {
          id: 4,
          name: "automated",
          img: "/automated it and rbotics/4.png",
          pdfLink: "/automated it and rbotics/pdf/A4.pdf",
        },

        {
          id: 5,
          name: "automated",
          img: "/automated it and rbotics/5.png",
          pdfLink: "/automated it and rbotics/pdf/A5.pdf",
        },

        {
          id: 6,
          name: "automated",
          img: "/automated it and rbotics/6.png",
          pdfLink: "/automated it and rbotics/pdf/A6.pdf",
        },
        {
          id: 7,
          name: "automated",
          img: "/automated it and rbotics/7.png",
          pdfLink: "/automated it and rbotics/pdf/A7.pdf",
        },
        {
          id: 8,
          name: "automated",
          img: "/automated it and rbotics/8.png",
          pdfLink: "/automated it and rbotics/pdf/A8.pdf",
        },
        {
          id: 10,
          name: "automated",
          img: "/automated it and rbotics/10.png",
          pdfLink: "/automated it and rbotics/pdf/A10.pdf",
        },
        {
          id: 11,
          name: "automated",
          img: "/automated it and rbotics/11.png",
          pdfLink: "/automated it and rbotics/pdf/A11.pdf",
        },
        {
          id: 12,
          name: "automated",
          img: "/automated it and rbotics/12.png",
          pdfLink: "/automated it and rbotics/pdf/A12.pdf",
        },
     
        {
          id: 13,
          name: "automated",
          img: "/automated it and rbotics/newA13.png",
          pdfLink: "/automated it and rbotics/pdf/newA13.pdf",
        },
        {
          id: 14,
          name: "automated",
          img: "/automated it and rbotics/14.png",
          pdfLink: "/automated it and rbotics/pdf/A14.pdf",
        },
        {
          id: 15,
          name: "automated",
          img: "/automated it and rbotics/15.png",
          pdfLink: "/automated it and rbotics/pdf/A15.pdf",
        },
        {
          id: 16,
          name: "automated",
          img: "/automated it and rbotics/16.png",
          pdfLink: "/automated it and rbotics/pdf/A16.pdf",
        },
        {
          id: 17,
          name: "automated",
          img: "/automated it and rbotics/17.png",
          pdfLink: "/automated it and rbotics/pdf/A17.pdf",
        },
        {
          id: 18,
          name: "automated",
          img: "/automated it and rbotics/18.png",
          pdfLink: "/automated it and rbotics/pdf/A18.pdf",
        },
        {
          id: 19,
          name: "automated",
          img: "/automated it and rbotics/19.png",
          pdfLink: "/automated it and rbotics/pdf/A19.pdf",
        },
        {
          id: 20,
          name: "automated",
          img: "/automated it and rbotics/20.png",
          pdfLink: "/automated it and rbotics/pdf/A20.pdf",
        },
        {
          id: 21,
          name: "automated",
          img: "/automated it and rbotics/21.png",
          pdfLink: "/automated it and rbotics/pdf/A21.pdf",
        },
        {
          id: 22,
          name: "automated",
          img: "/automated it and rbotics/22.png",
          pdfLink: "/automated it and rbotics/pdf/A22.pdf",
        },
        {
          id: 4,
          name: "automated",
          img: "/workforce transformation/4.png",
          pdfLink: "/workforce transformation/pdf/W4.pdf",
        },
        {
          id: 1,
          name: "workforce",
          img: "/workforce transformation/1.png",
          pdfLink: "/workforce transformation/pdf/W1.pdf",
        },
        {
          id: 2,
          name: "workforce",
          img: "/workforce transformation/2.png",
          pdfLink: "/workforce transformation/pdf/W2.pdf",
        },
        {
          id: 3,
          name: "workforce",
          img: "/workforce transformation/3.png",
          pdfLink: "/workforce transformation/pdf/W3.pdf",
        },
        {
          id: 5,
          name: "workforce",
          img: "/workforce transformation/5.png",
          pdfLink: "/workforce transformation/pdf/W5.pdf",
        },
        {
          id: 6,
          name: "workforce",
          img: "/workforce transformation/W6.png",
          pdfLink: "/workforce transformation/pdf/W6.pdf",
        },
        {
          id: 7,
          name: "workforce",
          img: "/workforce transformation/7.png",
          pdfLink: "/workforce transformation/pdf/W7.pdf",
        },
        {
          id: 8,
          name: "workforce",
          img: "/workforce transformation/8.png",
          pdfLink: "/workforce transformation/pdf/W8.pdf",
        },
        {
          id: 9,
          name: "workforce",
          img: "/workforce transformation/9.png",
          pdfLink: "/workforce transformation/pdf/W9.pdf",
        },
        {
          id: 10,
          name: "workforce",
          img: "/workforce transformation/W10.png",
          pdfLink: "/workforce transformation/pdf/W10.pdf",
        },
      ],
    };
  },
  mounted() {
    let self = this;
    // this.setActiveButton("care");
    // console.log("test", this.files["care"]);
    $("#videoModal").on("shown.bs.modal", function (e) {
      self.hide_video = false;
      setTimeout(() => {
        $("#video").attr("src", self.videoSrc);
      }, 500);
    });
    $("#videoModal").on("hide.bs.modal", function (e) {
      self.hide_video = true;
      $("#video").attr("src", self.videoSrc);
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
    openModal(link) {
      this.videoSrc = link;
      $("#videoModal").modal("show");
    },
    setActiveButton(button, flag = false) {
      console.log("setActiveButton", button);
      if (button != "winner") {
        this.activeData = this.cards.filter((data) => data.name == button);
      }
      this.activeBtn = button;
      if (flag) {
        setTimeout(() => {
          document.getElementById(`sec-${button}`).scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      }
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.nhip {
  .Sansrace-Regular {
    font-family: "Sansrace-Regular";
  }
  .nhip-content {
    .title-nhip {
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      color: #fff;
    }
    .detail {
      background-color: #f6f2fc;
      p {
        font-family: "Nexa-Light";
      }
    }
    .past-awards {
      .pointer {
        cursor: pointer;
      }
      background-image: linear-gradient(
        135deg,
        rgb(127, 41, 254),
        rgb(217, 65, 103)
      );
      p {
        font-family: "Sansrace-Regular";
        color: #23f0c7;
        font-size: 1.2rem;
        margin-bottom: 0px !important;
      }
    }
    .about-medal {
      h2 {
        font-family: "Sansrace-Regular";
      }
      .about-the-medal-text {
        color: #5f0fd0;
      }
      .best-adopter-medal-text {
        color: #d94167;
      }
      background-color: #ffffff;
      p {
        font-family: "Nexa-Light";
        .bold-text {
          font-family: "Nexa-Bold";
        }
      }
    }
    .timeline {
      background-color: #f2f2f5;
      h2 {
        color: #5f0fd0;
        font-family: "Sansrace-Regular";
      }
    }
  }
  .contact {
    background-image: linear-gradient(
      to right bottom,
      #6d77b9,
      #5b60a4,
      #49498e,
      #383379,
      #281e64,
      #21145b,
      #190a51,
      #120048,
      #120048,
      #120048,
      #120048,
      #120048
    );
    .contact-btn {
      background-image: linear-gradient(135deg, #23f0c7, #0088ff);
      padding: 8px 0px;
      border-radius: 32px;
      color: #fff;
      font-size: 26px;
      font-family: "Nexa-Bold";
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nhip-winners {
    .inner-box {
      display: inline-block;
    }
    .winner-btn {
      background-image: linear-gradient(241deg, #7f29fe, #d94167);
    }
    .care-btn {
      background-color: #0088ff;
    }
    .auto-btn {
      background-color: #23f0c7;
    }
    .work-btn {
      background-color: #f4d35e;
    }
    .btn {
      border-radius: 13px;
      font-family: "Sansrace-Regular";
      text-shadow: 0px 0px 0px #000, 0px 0px 0px #000, 0px 0px 0px #000,
        0px 0px 0px #000;
    }
  }

  .left-box-background {
    background: linear-gradient(to bottom, #291566 0%, #7f078a 100%);
    width: 38%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .content-back-img {
    background-image: url(.././assets/img/nhip/bg.png);
    width: 62%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .shortlisted-content {
    background-image: url(.././assets/img/nhip/bg.png);
    background-size: cover;
  }

  @media screen and (max-width: 1370px) {
    .left-box-background {
      width: 36%;
    }
    .content-back-img {
      width: 64%;
    }
  }
  @media screen and (max-width: 1200px) {
    .left-box-background {
      width: 34%;
    }
    .content-back-img {
      width: 66%;
    }
  }
  @media screen and (min-width: 1600px) {
    .left-box-background {
      width: 40%;
    }
    .content-back-img {
      width: 60%;
    }
  }
  @media screen and (min-width: 2000px) {
    .left-box-background {
      width: 42%;
    }
    .content-back-img {
      width: 58%;
    }
  }
  @media screen and (min-width: 2500px) {
    .left-box-background {
      width: 43%;
    }
    .content-back-img {
      width: 57%;
    }
  }
  @media screen and (min-width: 2860px) {
    .left-box-background {
      width: 44%;
    }
    .content-back-img {
      width: 56%;
    }
  }
}
</style>
