<template>
  <div class="nhip-shortlisted text-center px-3">
    <div v-if="desktop">
      <img
        class="title img-fluid"
        :src="require(`../../assets/img/nhip/${name}.png`)"
        alt=""
      />
    </div>
    <div class="row py-4">
      <div
        class="col-sm-4 col-6 col-lg-4 mb-4"
        v-for="(data, index) in awardsData"
        :key="index"
      >
        <a :href="data.pdfLink" target="_blank">
          <img
            :src="require(`../../assets/img/nhip${data.img}`)"
            class="img-fluid pointer"
            alt=""
          />
        </a>
      </div>
      <!-- <div class="col-sm-4 col-6 col-lg-4 mb-4">
              <img src="@/assets/img/nhip/Care redesign/1.png" class="img-fluid pointer" alt="" />
            </div>
            <div class="col-sm-4 col-6 col-lg-4 mb-4">
              <img src="@/assets/img/nhip/Care redesign/1.png" class="img-fluid pointer" alt="" />
            </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Shortlisted",
  props: ["awardsData", "name", "desktop"],
  mounted() {
    console.log("awardsData", this.awardsData);
  },
};
</script>
<style lang="scss" scoped>
.nhip-shortlisted {
  .pointer {
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {
  .nhip-shortlisted {
    margin-left: 10px;
    margin-right: 10px;

    .title {
      width: 60%;
    }
  }
}
</style>
